import React from 'react';

const Desk = () => {
  return (
    <section class="directore-desk bg-transparent repsonsive-directore-desk about-trans-globe-banner">
    <div class="container">
        <div class="title-sec">
            <h2>Welcome to Uni Place Overseas</h2>
            <a href="aboutus">Know more <i class="fa-solid fa-right-long"></i></a>
        </div>
        <div class="directore-inspired-details">
            <div class="swiper-slide">
                <div class="director-flex">
                    <div class="director-content-sec col-lg-12 col-md-12">
                        
                        
                        <p class="text-justify">Since our inception in 1992, Uni Place Overseas has been on a remarkable journey, 
                            evolving from offering International and Domestic Travel Solutions to becoming a leading provider of Abroad Education 
                            and IELTS Coaching services. Headquartered in Ahmedabad, Gujarat, we have carved a niche for ourselves with three decades of experience,
                             and we are proud to be the go-to destination for students aspiring to study abroad as international scholars.</p>

                        <p class="text-justify">Our success is driven by our commitment to excellence and the trust we have earned from countless satisfied 
                            students and parents. With an impressive 98.4% visa approval ratio and over 10,000 student enrolments, we have established a
                             strong presence with 11 operational abroad education offices in India and 2 in Australia.</p>

                        <p class="text-justify">At Uni Place Overseas, we are passionate about connecting students with the world's top universities. 
                            Our team of more than 100 trained advisors, backed by supporting executives across our offices, ensures that each 
                            student is provided with a comprehensive range of academic options and handpicked courses tailored to their ambitions.</p>

                        <p class="text-justify">Our journey to success has not been easy; it required perseverance, dedication, and the unwavering 
                            faith of both our students and their parents. Together, we have transformed dreams into magical realities, and we take
                             immense pride in the 650+ educational institutes we have partnered with worldwide, creating a network of smart, diverse, 
                             and goal-oriented industry professionals to help aspirants flourish in countries like Australia, UK, USA, Canada,
                              Europe, New Zealand, Dubai, and Singapore.</p>

                        <p class="text-justify">Looking ahead, our vision at Uni Place Overseas is to foster education globally,
                             reaching every corner of the country. Guiding ambitious students like true friends, we are committed 
                             to providing access to the world's best mentors and comprehensive solutions that facilitate admission 
                             to world-class universities abroad.</p>

                        <p class="text-justify">Our philosophy revolves around putting 'Students' Interest First,' 
                            and with the collective support and dedication of our team, we strive to prepare every student 
                            for a brilliant career overseas, making parents' dreams come true.</p>

                        <p class="text-justify">As Uni Place Overseas, we are delighted to be a part of your educational journey and promise to be your 
                            trusted companion in shaping a prosperous future abroad. Together, let's dream big and embark on this exciting adventure of 
                            knowledge and growth.</p>

                        <p class="text-justify">Welcome to Uni Place Overseas - Where Your Dreams Take Flight!</p>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  );
};

export default Desk;