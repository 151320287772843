import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const OfficeLocations = () => {
    return (
        <section className="our-office-locationss">
            <div className="container">
                <div className="contact-highlights">
                    <h3>Uniplace Overseas -  Head Office, India</h3>
                    <p>Uniplace Overseas 323/401 Corporate Park, Umashankar Joshi Marg, Beside Samudr NX, CG Road, Ahmedabad, India, Gujarat</p>
                    <p className="cta">Phone: +91 91739 29277 | Email: info@uniplaceoverseas.com</p>
                </div>
                <section className="how-to-UniPlaceOverseas-can-sec pt-4 pb-2">
                    <div className="book-a-free-consaltation mt-2">
                        <div id="ff-compose"></div>
                    </div>
                </section>
            </div>
        </section>
    );
}

export default OfficeLocations;