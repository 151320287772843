import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const EmpOpp = (props) => {
    return (
        <section className="directore-desk opportunities">
            <div className="container">
                <div className="title-sec">
                    <h2>{props.heading}</h2>
                </div>
                <div className="directore-inspired-details">
                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <div className="directore-img">
                                <img src={props.img} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                            <p className="pb-3">{props.para1}
                            </p>
                            <p className="pb-3">{props.para2}</p>
                            <p className="pb-3">
                                {props.para3}
                            </p>
                            <p>
                                {props.para4}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default EmpOpp;