import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeadingBar from './headingBar';
import CareerOpp from './study/careerOpp';
import EmpOpp from './study/empOpp';
import Intake from './study/intake';
import ExploreDest from './study/exploreDest';
import Why from './study/why';
import Know from './study/know';
import Benefit from './study/benefit';
import AdmReq from './study/admReq';
import StudyProcess from './study/studyProcess';
import Universities from './study/universities';

const StudyCanada = () => {
  return (
    <div>
        <HeadingBar heading="Study in Canada" />
        <Why countryName="Canada" 
          countryImg="images/Canada.png"
          para1="Australia flaunts its cultural divergence and exemplary lifestyle luxuries that all the residents whether temporary or permanent, are bound to enjoy. With a unique mix of thriving cities and regional epicenters, vast open spaces and 2 incredible landscapes, it’s no wonder Australia attracts students from all over the world. "
          para2="It sets an outstanding example of Multicultural World View with its open acceptance of people and their perspectives. Prospering and growing together in all areas is what makes Australia your perfect study abroad destination. "
          studyImg="images/Australia-study.png"
        />
        <Know
          countryName="Canada" 
          img1="images/trash/Pttaw City1.png"
          img2="images/trash/PttawaCity2.png"
          img3="images/trash/PttawaCity3.png"
          img4="images/trash/PttawaCity4.png"
          img5="images/trash/PttawaCity6.png"
          img6="images/trash/PttawaCity7.png"
          img7="images/trash/PttawaCity8.png"
          img8="images/trash/PttawaCity9.png"
          capital="Canberra"  population="27.10 Million" lang="English" noOfIntStud="8,69,709" livingCost="15000 AUD - 24000 AUD"
          currency="Australian Dollar" noOfUni="43" 
        />
        <Benefit
          countryName="Canada" 
          heading="Australia is setting international standards for excellence in education through its world-leading institutions, state-of-art facilities, thought-leading creativity, world className hospitality, and extensive student support services. As the largest country in Oceania and the 6th largest country by area, Australia is one of the most beautiful and highly urbanized countries of the world. Canberra is the capital city, with other student centric cities such as Sydney, Melbourne, Adelaide, Perth, and so on."
          ben1Head="World ClassName Academic Recognition "
          ben1Desc1="Australia provides high-quality of theoretical as well as a practical learning experience that is
          acknowledged by the best education bodies and companies across the globe."
          ben2Head="Cultural Diversity and Australian Life"
          ben2Desc1="Students all around the world flock in for studying in this education hub. Thus making it a home to  aspirants from diverse cultures and traditional backgrounds."
          ben2Desc2="Australia is also a fantastic place to live with its great weather, safe living, adventurous natural beauty and lifestyle which attracts students and travellers both, making it a multicultural hub. "
          ben3Head="Australian Qualification Framework with wide range of Courses"
          ben3Desc1="The 39 Australian universities are government-funded and are closely monitored to render the best learning experirnce."
          ben3Desc2="Australia provides multitudes of niche programs from Diploma, Bachelors, Masters to Research level in different fields."
          ben4Head="Cost-Effective & Work while studying "
          ben4Desc1="Staying in Australia while pursuing higher education is extremely pocket-friendly, comparatively
          lesser than other countries."
          ben4Desc2="Australian Students Visa holders are eligible to work 40 hours a fortnight during their academic session and unlimited hours during vacation and schedule breaks."
          ben5Head="Courses Options and Duration"
          ben5Desc1="Bachelor degree courses in Australia are of 3 years unlike USA & Canada. Business, Art, Design, Science, Humanities degree programs are of 3 years, while Engineering, Law, Social Work, Physiotherapy, are of 4 years, Dual Bachelors are of 5 years. Medicine and Dental courses range from 5 to 6.5 years. Beside UG Medicine, Nursing and Law also have a Graduate Entry option in Australia."
          ben6Head="Scholarships"
          ben6Desc1="As an International students, there are there are an array of scholarships and bursaries provided by the universities for Indian students.  These grants, scholarships and bursaries are offered by the Australian Government and the education providers to assist students. Annually A$ 200 million are allotted by the Australian Government for handling out these scholarship to the deserving international students."
        />
        <AdmReq/>
        <StudyProcess
          countryName="Canada"
          countryImg="images/australia process.jpeg"
        />
        <CareerOpp
          heading="Careers And Work Opportunities"
          img1="images/Arts-and-Humanities.png"
          para1="Arts and Humanities"
          img2="images/trash/business-icon.png"
          para2="Business and Commerce MBA"
          img3="images/trash/Creative-Visual.png"
          para3="Creative, Visual and Performing Arts"
          img4="images/trash/Computer-Science.png"
          para4="Computer Science and Information Technology"
          img5="images/trash/Interior-and-Spatial-Designer.png"
          para5="Design and Architecture"
          img6="images/trash/Engineering-and-Technology.png"
          para6="Engineering and Technology"
          img7="images/trash/Medicine-and-Life-Sciences.png"
          para7="Nursing, Pharmacy and Health Sciences"
          img8="images/trash/Natural-Sciences.png"
          para8="Agriculture, Life Sciences "
        />
        <EmpOpp
          heading="Employment Opportunities"
          img="images/trash/Employement.png"
          para1="Australia is one of the most sought post study destinations for International students. The
          continuous demand for the skilled workforce in the country has given a boost to the education sector;
          which continues to attract and sustain thousands of International students every year."
          para2="The post-study work visa in Australia allows International students to stay in the country
          for upto 4
          years and work in their chosen profession after completing their studies."
          para3="The Post study work visa generally allows you to stay in the country between 2-4 years depending on the
          length of your course. In recent years, Australian government has committed to support the regional areas."
          para4="Students who have completed their graduation/bachelors education in Australia are eligible to put forward
          an application for a two year post-study work visa only after completing minimum study duration of 2
          years. Similarly, you are eligible for a 3 year post-study work visa after you have completed your
          Master’s by research and 4 years post study work permit years after completing PhD course in Australia."
        />
        <Intake intakes="February,  July and October"/>
        <Universities
          heading="Australian Universities"
          heading2="Group of 8 Universities"
          universities={[ 
          {
            img: "images/trash/22. University of Wollongong and UoW College.png",
            head: "Australian National University",
            para: "Australia"
          },
          {
            img: "images/trash/University-of-Western-Australia.png",
            head: "The University of Western Australia",
            para: "Australia"
          },
          {
            img: "images/trash/University-of-Queensland.png",
            head: "University of Queensland",
            para: "Australia"
          },
          {
            img: "images/trash/Monash-University.png",
            head: "Monash University",
            para: "Australia"
          },
          {
            img: "images/trash/University-of-Melbourne.png",
            head: "University of Melbourne",
            para: "Australia"
          },
          {
            img: "images/trash/University-of-Sydney.png",
            head: "University of Sydney",
            para: "Australia"
          },
          {
            img: "images/trash/University-of-New-South-Wales.png",
            head: "University of New South Wales",
            para: "Australia"
          },
          {
            img: "images/trash/University-of-Adelaide.png",
            head: "University of Adelaide",
            para: "Australia"
          },
        ]}
        />
        <ExploreDest
          country="Canada"
          heading="Explore More Destinations"
          destinations={[
            {
              img: "images/UK.png",
              country: "United Kingdom",
              noOfUni: "50+ Universities",
            },
            {
              img: "images/New-Zealand.png",
              country: "New Zealand",
              noOfUni: "45+ Universities",
            },
            {
              img: "images/USA.png",
              country: "United States",
              noOfUni: "50+ Universities",
            },
            {
              img: "images/New-Zealand.png",
              country: "New Zealand",
              noOfUni: "45+ Universities",
            },
          ]}
        />
    </div>
  );
};

export default StudyCanada;