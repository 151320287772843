import {React, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swiper from 'swiper';

const ExploreDest = (props) => {

    var swiper;
    useEffect(() => {
        swiper = new Swiper(".swiper-more-destinations", {
            slidesPerView: 1,
            spaceBetween: 20,
            loop: true,
            autoplay: {
              delay: 2500,
              disableOnInteraction: false,
            },
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            breakpoints: {
              575: {
                slidesPerView: 1,
                spaceBetween: 24,
                resistanceRatio: 0.85,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 28,
                resistanceRatio: 0.85,
              },
              980: {
                slidesPerView: 2,
                spaceBetween: 28,
                resistanceRatio: 0.85,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 0,
                resistanceRatio: 0,
              },
            },
          });
    }, []);

    return (
        <section className="explore-more-dest">
            <div className="container">
                <div className="title-sec">
                    <h2>{props.heading}</h2>
                </div>
                <div className="explore-flag-card">
                    <div className="swiper swiper-more-destinations">
                        <div className="swiper-wrapper">
                            {props.destinations.map((destination) => {
                            return (
                            <div className="swiper-slide mobile-view-margin">
                                <div className="card-first text-center country-card-list">
                                    <div className="client-details">
                                        <div className="flag-img">
                                            <img src={destination.img} alt=""/>
                                        </div>
                                        <div className="country-name-details">
                                            <h4>Study In {destination.country}</h4>
                                            <h5>{destination.noOfUni}</h5>
                                            <a href="uk-details">Explore Now <i className="fa-solid fa-right-long"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )}
                            )}
                            
                        </div>
                        <div className="swiper-button-next"></div>
                        <div className="swiper-button-prev"></div>
                        <div className="swiper-pagination"></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ExploreDest;