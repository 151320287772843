import React from 'react';

const LeadershipTeam = () => {
  return (
    <div>
    <section class="all-team-member about-page-lead-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <h2>Leadership Team</h2>
                </div>
            </div>
        </div>
    </section>
    <section class="all-team-member bg-transparent">
        <div class="container">
            <div class="row">

                <div class="col-lg-12 col-md-12">
                    <div class="title-sec mb-4">
                        <h2>Mr. ABC – Founding Director and Owner</h2>
                    </div>
                    <div class="team-introduction">
                      <p> Write details here</p>
                       
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="all-team-member">
        <div class="container">
            <div class="row">

                <div class="col-lg-12 col-md-12">
                    <div class="title-sec mb-4">
                        <h2>Mrs. ABC - Co-Founder, Chief Executive UniPlaceOverseas</h2>
                    </div>
                    <div class="team-introduction">
                      <p> Write details here</p>
                      
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="all-team-member  bg-transparent">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="team-introduction">
                        <div class="title-sec mb-4">
                            <h2>Mr. ABC - Managing Director UniPlaceOverseas</h2>
                        </div>
                        <p> Write details here</p>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="all-team-member">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="title-sec mb-4">
                        <h2>Mrs. ABC - Business Head and Partnerships UniPlaceOverseas</h2>
                    </div>
                    <div class="team-introduction">
                      <p> Write details here</p>
                      
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
};

export default LeadershipTeam;