import React from 'react';
import '../../static/assets/front/css/style.css';
import '../../css/form.css';

const BookConsultancy = () => {
    return (
        <section class="university-partners">
        <div class="container">
        <section class="how-to-UniPlaceOverseas-can-sec pt-4 pb-2">
            <div class="book-a-free-consaltation mt-2">
                <div id="ff-compose"></div>
               </div>
        </section>
        </div>
</section >
  );
};

export default BookConsultancy;