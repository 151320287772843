import {React, useEffect} from 'react';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';


const Clients = () => {
    var swiper;
    useEffect( () => {
        swiper = new Swiper(".swiper", {
            slidesPerView: 1,
            spaceBetween: 20,
            direction: 'horizontal',
            loop: true,
            // modules: [Navigation, Pagination],
            autoplay: {
              delay: 2500,
              disableOnInteraction: false,
            },
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            breakpoints: {
              575: {
                slidesPerView: 1,
                spaceBetween: 24,
                resistanceRatio: 0.85,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 28,
                resistanceRatio: 0.85,
              },
              980: {
                slidesPerView: 2,
                spaceBetween: 28,
                resistanceRatio: 0.85,
              },
              1380: {
                slidesPerView: 2,
                spaceBetween: 0,
                resistanceRatio: 0,
              },
              1440: {
                slidesPerView: 3,
                spaceBetween: 0,
                resistanceRatio: 0,
              },
            }
        });
    }, []);
    
    // const swiper = new Swiper('.swiper', {
    //     // Optional parameters
    //     direction: 'horizontal',
    //     loop: true,
      
       
    //     // Navigation arrows
    //     navigation: {
    //       nextEl: '.swiper-button-next',
    //       prevEl: '.swiper-button-prev',
    //     },
      
       
    //   });
    
  return (
    <section className="client-say py-0">
        <div className="container">
            <div className="title-sec">
                <h2>Voices of Inspiration: Client Testimonials That Instill Trust, Motivation, and Excitement!</h2>
                <a href="testimonial">view more <i className="fa-solid fa-right-long"></i></a>
            </div>
            <div className="clients-card our-clients-say">
                <div className="swiper swiper-clients-say">
                    <div className="swiper-wrapper">
                       
                        <div className="swiper-slide">
                            <div className="client-detail-img">
                                <div className="client-img">
                                    <img src="images/students/zenith-vaghasia.png" alt=""/>
                                </div>
                                <div className="card-first">
                                    <div className="client-details">
                                        <h4>Zenith Vaghasia</h4>
                                        <span><strong>MA Sports Broadcast Journalism</strong></span>
                                        <span>Staffordshire University</span>
                                        <span>PTE Score: 90/90</span>
                                        <div className="quote-img">
                                            <img src="images/Quote.svg" alt=""/>
                                        </div>
                                        <p>Uni Place Overseas has been a fantastic companion throughout my application and
                                            coaching journey.
                                            Preparing for IELTS and PTE was something I was scared of for a long time,
                                            but just 7 days in at
                                            Uni Place Overseas I felt confident to appear for the exams. They are the only one
                                            who could get you
                                            ready in such a short time. The coaching environment was also a big help in
                                            focusing and studying
                                            effectively each day. With the help of Uni Place Overseas I have finished my
                                            Bachelors from QUT in
                                            Australia and my Masters - MA Sports Broadcast Journalism from Staffordshire
                                            University in UK. The
                                            experience has been fantastic and they are ever reliable in choosing your
                                            course and country of
                                            studies.</p>
                                        <a href="testimonial">read more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="swiper-slide">
                            <div className="client-detail-img">
                                <div className="client-img">
                                    <img src="images/students/zenith-vaghasia.png" alt=""/>
                                </div>
                                <div className="card-first">
                                    <div className="client-details">
                                        <h4>Mit Patel</h4>
                                        <span><strong>MA Sports Broadcast Journalism</strong></span>
                                        <span>Staffordshire University</span>
                                        <span>PTE Score: 90/90</span>
                                        <div className="quote-img">
                                            <img src="images/Quote.svg" alt=""/>
                                        </div>
                                        <p>Uni Place Overseas has been a fantastic companion throughout my application and
                                            coaching journey.
                                            Preparing for IELTS and PTE was something I was scared of for a long time,
                                            but just 7 days in at
                                            Uni Place Overseas I felt confident to appear for the exams. They are the only one
                                            who could get you
                                            ready in such a short time. The coaching environment was also a big help in
                                            focusing and studying
                                            effectively each day. With the help of Uni Place Overseas I have finished my
                                            Bachelors from QUT in
                                            Australia and my Masters - MA Sports Broadcast Journalism from Staffordshire
                                            University in UK. The
                                            experience has been fantastic and they are ever reliable in choosing your
                                            course and country of
                                            studies.</p>
                                        <a href="testimonial">read more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="swiper-slide">
                            <div className="client-detail-img">
                                <div className="client-img">
                                    <img src="images/students/zenith-vaghasia.png" alt=""/>
                                </div>
                                <div className="card-first">
                                    <div className="client-details">
                                        <h4>Nikunj Patel</h4>
                                        <span><strong>MA Sports Broadcast Journalism</strong></span>
                                        <span>Staffordshire University</span>
                                        <span>PTE Score: 90/90</span>
                                        <div className="quote-img">
                                            <img src="images/Quote.svg" alt=""/>
                                        </div>
                                        <p>Uni Place Overseas has been a fantastic companion throughout my application and
                                            coaching journey.
                                            Preparing for IELTS and PTE was something I was scared of for a long time,
                                            but just 7 days in at
                                            Uni Place Overseas I felt confident to appear for the exams. They are the only one
                                            who could get you
                                            ready in such a short time. The coaching environment was also a big help in
                                            focusing and studying
                                            effectively each day. With the help of Uni Place Overseas I have finished my
                                            Bachelors from QUT in
                                            Australia and my Masters - MA Sports Broadcast Journalism from Staffordshire
                                            University in UK. The
                                            experience has been fantastic and they are ever reliable in choosing your
                                            course and country of
                                            studies.</p>
                                        <a href="testimonial">read more</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="swiper-slide">
                            <div className="client-detail-img">
                                <div className="client-img">
                                    <img src="images/students/zenith-vaghasia.png" alt=""/>
                                </div>
                                <div className="card-first">
                                    <div className="client-details">
                                        <h4>Ayush Patel</h4>
                                        <span><strong>MA Sports Broadcast Journalism</strong></span>
                                        <span>Staffordshire University</span>
                                        <span>PTE Score: 90/90</span>
                                        <div className="quote-img">
                                            <img src="images/Quote.svg" alt=""/>
                                        </div>
                                        <p>Uni Place Overseas has been a fantastic companion throughout my application and
                                            coaching journey.
                                            Preparing for IELTS and PTE was something I was scared of for a long time,
                                            but just 7 days in at
                                            Uni Place Overseas I felt confident to appear for the exams. They are the only one
                                            who could get you
                                            ready in such a short time. The coaching environment was also a big help in
                                            focusing and studying
                                            effectively each day. With the help of Uni Place Overseas I have finished my
                                            Bachelors from QUT in
                                            Australia and my Masters - MA Sports Broadcast Journalism from Staffordshire
                                            University in UK. The
                                            experience has been fantastic and they are ever reliable in choosing your
                                            course and country of
                                            studies.</p>
                                        <a href="testimonial">read more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                       

                    </div>
                    <div className="swiper-button-prev"></div>
                    <div className="swiper-button-next"></div>
                    
                </div>
            </div>
        </div>
    </section>
  );
};

export default Clients;