import React from 'react';

const BeyondHorizon = () => {
  return (
    <section className="all-team-member">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="title-sec mb-4">
                        <h2>UniPlaceOverseas – Beyond the Horizon, Always!</h2>
                    </div>
                    <div className="team-introduction">
                        <p>
                          At UniPlace Overseas, we are driven by a profound belief that everyone should have access to their dream education.
                           Our mission is to open the gates to global education, a gift that will forever impact the next generation.
                            Our inspiration lies in making study abroad opportunities achievable and transformative, providing a platform
                             for ambitious students to prepare for a brilliant future overseas. </p>
                        <p>At UniPlace Overseas, we are proud of the trust thousands of Indian parents place in our achievements. 
                          Their unwavering belief inspires us to work tirelessly in shaping the perfect career path for their children. 
                          While fulfilling the dream of studying abroad in top-ranking universities requires courage and investment,
                           we stand by you as partners to provide the best and most suitable start for your child's journey.
                         </p>
                         <p>
                          To all the brave parents supporting their ambitious students, we emphasize the importance of being an integral part of their educational expedition.
                           It is crucial to estimate expenditures in all areas, consider possibilities and challenges, explore all options thoroughly to combat stress, 
                           and provide abundant emotional support to your children. Your child's future is our responsibility, and as a team and a true friend,
                           we are committed to standing by your child's study abroad quest. Together, we will pave the way for a promising future for your child.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default BeyondHorizon;