import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeadingBar from './headingBar.js';
import OfficeLocations from './contactus/officeLocations.js';

const ContactUs = () => {
  return (
    <div>
      <HeadingBar heading="Contact Us"/>
      <OfficeLocations/>
    </div>
  );
};

export default ContactUs;