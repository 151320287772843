import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Join from './about/joinTeam/join';
import HeadingBar from './headingBar';

const JoinTeam = () => {
  return (
    <div>
      <HeadingBar heading="Join our team at Uni Place Overseas"/>
      <Join/>
    </div>
  );
};

export default JoinTeam;