import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeadingBar from './headingBar';

const Services = () => {
  return (
    <div>
        <HeadingBar heading="Services" />
        
    </div>
  );
};

export default Services;