import React from 'react';

const Banner = () => {
  return (
    <section className="banner-slider">
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active"
                    aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"   
                aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"  
                    aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3"  
                    aria-label="Slide 4"></button>
            </div>
            <div className="carousel-inner">
                <div className="carousel-inner">
                                                            
                    <div className="carousel-item active">

                      <a href="#" target="Slide 2">
                        <img src="uploads/banners/1689235278.jpg" className="d-block w-100 l-50" alt="..."/>
                      </a>
                    </div>
              
                    <div className="carousel-item">
                        <a href="#" target="Slide 2">
                            <img src="uploads/banners/second.jpg" className="d-block w-100 l-50" alt="Slide 2"/>
                        </a>
                    </div>
                    <div className="carousel-item">
                        <a href="#" target="Slide 3">
                            <img src="uploads/banners/1689235278.jpg" className="d-block w-100 l-50" alt="Slide 3"/>
                        </a>
                    </div>
                    <div className="carousel-item">
                        <a href="#" target="Slide 4">
                            <img src="uploads/banners/second.jpg" className="d-block w-100 l-50" alt="Slide 4"/>
                        </a>
                    </div>
                </div>
                
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>      
             </div>            
        </div>
    </section>
  );
};

export default Banner;