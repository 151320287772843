import React from 'react';

const Footer = () => {
    return (
        <footer className="footer-sec">
            <div className="container">
                <div className="footer-first-menu">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-6">
                            <div className="first-menu-list">
                                <h5>Quick Links</h5>
                                <ul>
                                    <div className="home-address"></div>
                                    <li><a href="aboutus"> About Us </a></li>
                                    <li><a href="services"> Our Services </a></li>
                                    <li><a href="australia-details">Explore Study Destinations</a></li>
                                    <li><a href="sitemap">Sitemap </a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-6">
                            <div className="first-menu-list">
                                <h5>Study Abroad</h5>
                                <ul>
                                    <div className="home-address"></div>
                                    <li><a href="australia-details"> Study in Australia </a></li>
                                    <li><a href="new-zealand-details"> Study in New Zealand </a></li>
                                    <li><a href="uk-details"> Study in UK </a></li>
                                    <li><a href="usa-details"> Study in USA </a></li>
                                    <li><a href="canada-details"> Study in Canada </a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-6">
                            <div className="first-menu-list">
                                <h5>Ahmedabad - ISKCON</h5>
                                <ul>
                                    <li><a href="#">
                                        <div className="home-address">
                                            <i className="fa-solid fa-house-chimney"></i>

                                            <p>323/401 Corporate Park, Umashankar Joshi Marg, Beside Samudr NX, CG Road, Ahmedabad, India, Gujarat</p>

                                        </div>
                                        <div className="home-address">
                                            <i className="fa-solid fa-phone"></i>
                                            <div>
                                                <a href="tel:+91 91739 29277">
                                                    <p>+91 91739 29277</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="home-address">
                                            <i className="fa-solid fa-envelope"></i>
                                            <div>
                                                <a href="mailto:info@uniplaceoverseas.com">
                                                    <p>info@uniplaceoverseas.com</p>
                                                </a>
                                            </div>
                                        </div> </a></li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-6">
                            <div className="first-menu-list">
                                <h5>Gold Coast</h5>
                                <ul>
                                    <li><a href="#">
                                        <div className="home-address">
                                            <i className="fa-solid fa-house-chimney"></i>

                                            <p>Level 10, 36 Marine Parade Commertial Tower</p>

                                        </div>
                                        <div className="home-address">
                                            <i className="fa-solid fa-phone"></i>
                                            <div>
                                                <a href="tel:+61 45000000">
                                                    <p>+61 450 000 00</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="home-address">
                                            <i className="fa-solid fa-envelope"></i>
                                            <div>
                                                <a href="mailto:info@uniplaceoverseas.com">
                                                    <p>info@uniplaceoverseas.com</p>
                                                </a>
                                            </div>
                                        </div>  
                                    </a></li>

                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="container">
                <div className="office-location d-none">
                    <h4 className="ofc-location-title pb-0">Office Location</h4>
                    <div className="row">
                        <div className="col-lg-3 col-md-3 mb-4">
                            <div className="service-first our-address">
                                <div className="location-icon">
                                   
                                        <h5>Gold Coast</h5>
                                </div>
                                <div className="home-address">
                                    <i className="fa-solid fa-house-chimney"></i>
                                    
                                        <p>Level 10, 36 Marine Parade Commertial Tower</p>
                                        
                                </div>
                                <div className="home-address">
                                    <i className="fa-solid fa-phone"></i>
                                    <div>
                                        <a href="tel:+61 45000000">
                                            <p>+61 450 000 00</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="home-address">
                                    <i className="fa-solid fa-envelope"></i>
                                    <div>
                                        <a href="mailto:info@uniplaceoverseas.com">
                                            <p>info@uniplaceoverseas.com</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-3 col-md-3 mb-4">
                            <div className="service-first our-address">
                                <div className="location-icon">
                                   
                                        <h5>Ahmedabad - ISKCON</h5>
                                </div>
                                <div className="home-address">
                                    <i className="fa-solid fa-house-chimney"></i>
                                   
                                        <p>323/401 Corporate Park, Umashankar Joshi Marg, Beside Samudr NX, CG Road, Ahmedabad, India, Gujarat</p>
                                       
                                </div>
                                <div className="home-address">
                                    <i className="fa-solid fa-phone"></i>
                                    <div>
                                        <a href="tel:+91 91739 29277">
                                            <p>+91 91739 29277</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="home-address">
                                    <i className="fa-solid fa-envelope"></i>
                                    <div>
                                        <a href="mailto:info@uniplaceoverseas.com">
                                            <p>info@uniplaceoverseas.com</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="copy-right">
                <div className="container">
                    <h5><span>Copyright © 2023, </span> Uniplace Overseas. All Rights Reserved.</h5>
                </div>
            </div>
        </footer>
    )
}

export default Footer;