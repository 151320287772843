import React from 'react';
import Banner from './home/banner.js';
import Desk from './home/desk.js';
import Explorer from './home/explorer.js';
import Clients from './home/clients.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import Partners from './home/partners.js';
import BookConsultancy from './home/bookConsultancy.js';

const HomePage = () => {
  return (
    <div>
      <Banner/>
      <Desk/>
      <Explorer/>
      <Clients/>
      <Partners/>
      <BookConsultancy/>
    </div>
  );
};

export default HomePage;