import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Know = (props) => {
    return (
        <section className="scholarship-sec country-list-card">
            <div className="container">
                <div className="title-sec">
                    <h2>Know More About {props.countryName}</h2>
                </div>
                <div className="scholarship-card">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="scholarship-details">
                                <div>
                                    <img src={props.img1} alt=""/>
                                </div>
                                <div>
                                    <h3>Capital</h3>
                                    <h5>{props.capital}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="scholarship-details">
                                <div>
                                    <img src={props.img2} alt=""/>
                                </div>
                                <div>
                                    <h3>Population</h3>
                                    <h5>{props.population}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="scholarship-details">
                                <div>
                                    <img src={props.img3} alt=""/>
                                </div>
                                <div>
                                    <h3>Language</h3>
                                    <h5>{props.lang}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="scholarship-details">
                                <div>
                                    <img src={props.img4} alt=""/>
                                </div>
                                <div>
                                    <h3>International Students</h3>
                                    <h5>{props.noOfIntStud}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="scholarship-details">
                                <div>
                                    <img src={props.img5} alt=""/>
                                </div>
                                <div>
                                    <h3>Cost Of Living</h3>
                                    <h5>{props.livingCost}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mobile-view-margin">
                            <div className="scholarship-details">
                                <div>
                                    <img src={props.img6} alt=""/>
                                </div>
                                <div>
                                    <h3>Currency</h3>
                                    <h5>{props.currency}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mobile-view-margin">
                            <div className="scholarship-details">
                                <div>
                                    <img src={props.img7} alt=""/>
                                </div>
                                <div>
                                    <h3>Universities</h3>
                                    <h5>{props.noOfUni}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="scholarship-details">
                                <div>
                                    <img src={props.img8} alt=""/>
                                </div>
                                <div>
                                    <h3>Weather</h3>
                                    <h5>{props.countryName}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Know;