import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const StudyProcess = (props) => {
    return (
        <section className="admission-timeline">
            <div className="container">
                <div className="title-sec">
                    <h2>Study in {props.countryName} Process</h2>
                </div>
                <div className="australia-process">
                    <img src={props.countryImg} alt=""/>
                </div>
            </div> 
        </section>
                );
}

export default StudyProcess;