import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Intake = (props) => {
    return (
        <section className="intakes-main-sec">
            <div className="container">
                <div className="title-sec">
                    <h2>Intakes</h2>
                </div>
                <p className="p-3">{props.intakes}</p>
            </div>
        </section>
    );
}

export default Intake;