import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Why = (props) => {
    return (
        <div>
            <section className="study-canada">
                <div className="container">
                    <div className="study-in-canada-main">
                        <div className="canada-card">
                            <img src={props.countryImg} alt=""/>
                        </div>
                        <div className="banner-btn">
                            <h2>Study In {props.countryName}</h2>
                        </div>
                    </div>
                </div>
            </section>

            <section className="directore-desk why-study-canada">
                <div className="container">
                    <div className="directore-inspired-details">
                        <div className="row">
                            <div className="col-lg-8 col-md-8">
                                <div className="title-sec">
                                    <h2>Why Study in {props.countryName} ?</h2>
                                </div>
                                <div className="all-citys-spacing">
                                    <p className="pb-3">{props.para1}</p>
                                    <p>{props.para2}</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="directore-img">
                                    <img src={props.studyImg} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Why;