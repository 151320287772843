import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const AdmReq = (props) => {
    return (
        <section className="scholarship-sec country-list-card admission-req-main">
            <div className="container">
                <div className="title-sec">
                    <h2>Admission Requirements</h2>
                </div>
                <div className="scholarship-card">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="scholarship-details">
                                <div>
                                    <img src="images/trash/Copy-of-a-valid-passport.png" alt=""/>
                                </div>
                                <div>
                                    <h5>Copy of a Valid Passport</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="scholarship-details">
                                <div>
                                    <img src="images/trash/Academic-Transcripts.png" alt=""/>
                                </div>
                                <div>
                                    <h5>Academic Transcripts</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="scholarship-details">
                                <div>
                                    <img src="images/trash/English-proficiency-scores.png" alt=""/>
                                </div>
                                <div>
                                    <h5>English proficiency scores
                                        (TOEFL/IELTS/PTE)</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="scholarship-details">
                                <div>
                                    <img src="images/trash/Letters-of-Recommendation.png" alt=""/>
                                </div>
                                <div>
                                    <h5>Letters of Recommendation</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-md-4 mobile-view-margin">
                            <div className="scholarship-details">
                                <div>
                                    <img src="images/trash/CV-Resume-Essays.png" alt=""/>
                                </div>
                                <div>
                                    <h5>CV and Resume</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mobile-view-margin">
                            <div className="scholarship-details">
                                <div>
                                    <img src="images/trash/Statement-of-Purpose.png" alt=""/>
                                </div>
                                <div>
                                    <h5>Statement of Purpose (SOP) & GTE forms</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AdmReq;