import React from 'react';

const HeadingBar = (props) => {
  return (
    <div className="breadcrumb-wrapper">
        <div className="container">
            <ul className="list-unstyled">
                <li><a href="/">Home</a></li>
                <li>></li>
                <li>{props.heading}</li>
            </ul>
        </div>
    </div>
  );
};

export default HeadingBar;