import React from 'react';

const WhyUs = () => {
  return (
    <section className="who-we-are-sec about-who-we-are">
        <div className="container">
            <div className="title-sec">
                <h2>Why Us? </h2>
            </div>
            <div className="who-we-are-top">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="who-we-are-details">
                            <p>At UniPlace Overseas, we are dedicated to guiding students towards their global aspirations. 
                               Our expert counsellors provide comprehensive support, ensuring a smooth study abroad journey. 
                               Backed by extensive research, we simplify the process for students and parents, empowering students
                               to reach their true potential on the international stage.</p>
                            <p>Our team of professional counsellors is dedicated to providing comprehensive support to students, 
                              helping them navigate the intricate journey of studying abroad. From identifying suitable opportunities to 
                              facilitating seamless transitions into their desired College/University, we strive to be the trusted mentors every student needs.</p>
                            <p><b>Being Members Of</b></p>
                            <ul className="about-list-sec">
                                <li>• AAERI supported by AEI of Australian High Commission with a network of 9 offices
                                    all over Gujarat
                                    and in Australia. </li>
                                <li>• International Consultants for Education and Fairs (ICEF). </li>
                                <li>• Association of International Educators (NAFSA). </li>
                                <li>• The British Council</li>
                            </ul>
                            <p>and various other High Commissions makes us the pioneers in Study Abroad Consultancy
                                since 1992.</p>


                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="we-are-img">
                            <img src="images/we-are.png" alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="who-we-are-details">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default WhyUs;