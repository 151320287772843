import React from 'react';

const Join = () => {
  return (
  <section class="career-details">
    <div class="container">
      <section class="what-we-value-main">
        <div class="title-sec">
          <h2>What We Value In Our Team Members</h2>
        </div>
        <div class="team-menber-parent">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <div class="team-member-name">
                <h5>Ambitious and Go Getters</h5>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="team-member-name">
                <h5>Leaders with Client Intelligence</h5>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="team-member-name">
                <h5>Quick Learners & Collaborators</h5>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="team-member-name">
                <h5>Teamwork & Teamspirit</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="join-our-team-main">
        <div class="title-sec">
          <h2>Join Our Team At Uni Place Overseas</h2>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="join-our-parent">
              <h4>ADMINISTRATIVE</h4>
              <ul>
                <li><b> Jr. Counselor – </b> India</li>
                <li><b> Applications Executive – </b> All countries</li>
                <li><b> Visa Executive – </b> All countries</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="join-our-parent">
              <h4>OPERATIONS</h4>
              <ul>
                <li><b> Associate HR – </b> Talent Acquisition, Contracts & Workplace Relations</li>
                <li><b> Destination Planner – </b> Destination & Product Strategies for growth</li>
                <li><b> Legal – </b> Legal & Commercial (Regulatory and Legislative Affairs)</li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="join-our-parent">
              <h4>SALES & MARKETING (Open to Travel)</h4>
              <ul>
                <li><b> Marketing Executive – </b> Campaign and Events</li>
                <li><b> Area Manager (Zonal) </b></li>
                <li><b> Business Head (National) </b></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="join-our-parent">
              <h4>DIGITAL MARKETING & SEO</h4>
              <ul>
                <li><b> Jr. Associate – </b> SEO Expert and Customer Marketing</li>
                <li><b> UX and Visual Designer </b></li>
                <li><b> Social Media - </b> Content Producer and Editor</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section class="for-career-main">
        
          <h2>Could not find your preferred role. Please send your resume to the below as we are constantly on a look out for a fresh talent.</h2>
        
        <div class="career-form">
          <a href="mailto:hr@transglobe-edu.com"><i class="fa fa-envelope"> </i> : abc@uniplaceoversease.com</a>
          <a href="tel:+9099363309"><i class="fa fa-mobile-phone"> </i> : +91 9099363309 </a>
        </div>
      </section>
    </div>
  </section>
  );
};

export default Join;