import React from 'react';
import {Link} from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';

const Header = () => {
  return (
    <header className="header">
      <nav className="navbar sticky-top parent-menu">
        <div className="container py-2">
          <a className="navbar-brand" href="/"><img src="images/Logo.png" alt="noimage"/></a>
          <div className="mobile-right-part">
            <div className="sc-media-icons desktop-icons">
              <ul>
                <li><a target="_blank" href="https://www.facebook.com/uniplaceoverseas/"><i
                      className="fa-brands fa-facebook-f"></i></a></li>
                <li><a target="_blank" href="https://www.linkedin.com/uniplaceoverseas/"><i
                      className="fa-brands fa-linkedin-in"></i></a></li>
                <li><a target="_blank" href="https://www.instagram.com/uniplaceoverseas/"><i
                      className="fa-brands fa-instagram"></i></a></li>
                <li><a target="_blank" href="https://www.youtube.com/channel/"><i
                      className="fa-brands fa-youtube"></i></a></li>
                <li><a target="_blank" href="https://www.twitter.com/uniplaceoversea"><i
                        className="fa-brands fa-twitter"></i></a></li>
              </ul>
             
              <div className="menu-list-location">
                <a href="#." className="btn btn-comman call-us-btn"><i className="fa-solid fa-location-dot"></i> Our Locations</a>
                <nav id="menu-nav" className="menu-nav-bar">
                    <a 
                    href="https://www.google.com/maps?q=link+for+following+address+level+10%2c+36+Marine+Parade+Commercial+Tower+%2c+36+Marine+Parade+%2c+Southport+QLD+4215+%2c+Gold+Coast+Australia&FORM=HDRSC6"
                    >
                      Gold Coast (Aus)
                    </a>
                    <a href="https://www.google.com/maps/dir/-27.869184,153.3247488/23.03281,72.55938/@-1.8573128,73.1084884,3z/data=!3m1!4b1!4m4!4m3!1m1!4e1!1m0?entry=ttu"> Ahmedabad (Ind) </a>
                </nav>
            </div>
            </div>
          </div>
          <div className="navbar-light navmenu-btn">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </div>
        
        <div className="bottom-menu py-2">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container">
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    About Uni Place Overseas
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                      <li><a href="aboutus" className="dropdown-item">About Us </a></li>
                      <li><a href="jointeam" className="dropdown-item">Join our team at Uni Place Overseas </a></li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="services"> Our Services</a>
                  </li>
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Study Destinations
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                      <li><a className="dropdown-item" href="studyaustralia">Study in Australia</a></li>
                      <li><a className="dropdown-item" href="studynewzealand">Study in New Zealand</a></li>
                      <li><a className="dropdown-item" href="studyuk">Study in UK</a></li>
                      <li><a className="dropdown-item" href="studyusa">Study in USA</a></li>
                      <li><a className="dropdown-item" href="studycanada">Study in Canada</a></li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="testimonial">Testimonials</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="contactus">Contact Us</a>
                  </li>
                </ul>
                <div className="mobile-right-part">
                  <div className="sc-media-icons mobile-icons">
                    <ul>
                        <li><a target="_blank" href="https://www.facebook.com/uniplaceoverseas/"><i
                            className="fa-brands fa-facebook-f"></i></a></li>
                      <li><a target="_blank" href="https://www.linkedin.com/uniplaceoverseas/"><i
                            className="fa-brands fa-linkedin-in"></i></a></li>
                      <li><a target="_blank" href="https://www.instagram.com/uniplaceoverseas/"><i
                            className="fa-brands fa-instagram"></i></a></li>
                      <li><a target="_blank" href="https://www.youtube.com/channel/"><i
                            className="fa-brands fa-youtube"></i></a></li>
                      <li><a target="_blank" href="https://www.twitter.com/uniplaceoversea"><i
                              className="fa-brands fa-twitter"></i></a></li> 
                    </ul>
                    <a href="#." className="btn btn-comman"><i className="fa-solid fa-location-dot"></i> Our Locations</a>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </nav>
    </header>
  );
};

export default Header;