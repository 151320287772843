import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeadingBar from './headingBar.js';
import Cards from './testimonials/cards.js';

const Testimonials = () => {
  return (
    <div>
      <HeadingBar heading="Testimonies"/>
      <Cards/>
    </div>
  );
};

export default Testimonials;