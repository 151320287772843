import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Cards = () => {
  return (
    <section className="testimonial-cards">
    <div className="container">
    <div className="title-sec">
                <h2>Testimonials</h2>
            </div><br />
	
      <div className="clients-card">
        <div className="row">
            
          <div className="col-lg-3 col-md-3">
            <div className="testimonial-detail-img">
              <div className="card-first">
                <div className="card-desc">

                
                <div className="client-img">
                  <img src="uploads/testimonials/1677564324.png" alt=""/>
                </div>
                <div className="client-details">
                  <h4>Zenith Vaghasia</h4>
                  <span><strong>MA Sports Broadcast Journalism</strong></span>
                  <span>Staffordshire University</span>
                  <span>PTE Score: 90/90</span>
                   <div className="quote-img">
                    <img src="uploads/testimonials/1677564324.png" alt=""/>
                  </div> 
                  </div>
                  </div>
                  <div className="client-details">
                  <p>Trans Globe has been a fantastic companion throughout my application and coaching journey. Preparing for IELTS and PTE was something I was scared of for a long time, but just 7 days in at Trans Globe I felt confident to appear for the exams. They are the only one who could get you ready in such a short time. The coaching environment was also a big help in focusing and studying effectively each day. With the help of Trans Globe I have finished my Bachelors from QUT in Australia and my Masters - MA Sports Broadcast Journalism from Staffordshire University in UK. The experience has been fantastic and they are ever reliable in choosing your course and country of studies.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
};

export default Cards;