import React from 'react';

const Explorer = () => {
  return (
    <section class="for-all-your-study">
        <div class="container">
            <div class="title-sec">
                <h2>Unlock Your Global Potential: Empowering Your Study Abroad Aspirations!</h2>
            </div>
            <div class="all-needs-part">
                <div class="row flex-direction-mobile">
                    <div class="col-lg-3 col-md-3 top-margin">
                        <div class="all-need-img change-width">
                            <img src="images/Explore-Option1.png" alt=""/>
                        </div>
                        <div class="all-need-data">
                            <h3>Explore Options</h3>
                            <p>Discover Your Path to Excellence with Handpicked Universities and Courses.</p>
                            <a href="university-list" class="btn btn-border">Explore Now</a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 top-margin">
                        <div class="all-need-img">
                            <img src="images/Counselling1.png" alt=""/>
                        </div>
                        <div class="all-need-data">
                            <h3>Empower Your Future </h3>
                            <p>Connect with Visionary Counselors: Expertise Rooted in Cutting-Edge Research and Unwavering Trust.</p>
                            <a href="services" class="btn btn-border"
                                >Explore Now</a>
                        </div>
                    </div> 
                    <div class="col-lg-3 col-md-3 top-margin ">
                        <div class="all-need-img left-align">
                            <img src="images/Enrolments1.png" alt=""/>
                        </div>
                        <div class="all-need-data">
                            <h3>Alumini</h3>
                            <p>Join Our Journey of Success: Embrace the Difference, Like Over 10,000 Dreamers Living Their Study Abroad Dream Today! </p>
                            <a href="testimonial" class="btn btn-border">Explore Now</a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-3 top-margin ">
                        <div class="all-need-img left-align">
                            <img src="images/Travel-And-Accomandation1.png" alt=""/>
                        </div>
                        <div class="all-need-data">
                            <h3>Accomodation</h3>
                            <p>Embark on Your Adventure with Confidence: Discover the Perfect Resources for Travel, Foreign Exchange, and Accommodation Guided by Our Experienced Experts.</p>
                            <a href="services" class="btn btn-border">Explore Now</a>
                        </div>
                    </div>

                </div>
                
            </div>
        </div>
    </section>
  );
};

export default Explorer;