import {React, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swiper from 'swiper';

const Universities = (props) => {
    var swiper;
    useEffect( () => {
     swiper = new Swiper(".swiper-top-universitys", {
            slidesPerView: 1,
            spaceBetween: 20,
            loop: true,
            autoplay: {
              delay: 2500,
              disableOnInteraction: false,
            },
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            breakpoints: {
              575: {
                slidesPerView: 1,
                spaceBetween: 24,
                resistanceRatio: 0.85,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 28,
                resistanceRatio: 0.85,
              },
              980: {
                slidesPerView: 2,
                spaceBetween: 28,
                resistanceRatio: 0.85,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 0,
                resistanceRatio: 0,
              },
            },
          });
          console.log(props);
    }, []);


    return (
        <section className="university-partners top-universitys">
            <div className="container">
                <div className="title-sec">
                    <h2>{props.heading}</h2>
                </div>
                <h5 className="mt-5">{props.heading2}</h5>
                <div className="swiper swiper-top-universitys">
                    <div className="swiper-wrapper">
                                    {props.universities.map(university => {
                                    return (
                                    <div className="swiper-slide">
                                        <div className="client-detail-img">
                                            <div className="card-first">
                                                <div className="top-uni-logo">
                                                    <img src={university.img} alt=""/>
                                                </div>
                                                <div className="client-details pt-0">
                                                    <h4>{university.head}</h4>
                                                    <p>{university.para}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    );
                                    })
                                    }    
                    </div>
                                <div className="swiper-button-next"></div>
                                <div className="swiper-button-prev"></div>
                                <div className="swiper-pagination"></div>
                </div>
                           
            </div>
                
        </section>
                    );
}

                    export default Universities;