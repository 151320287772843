import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import WhyUs from './about/aboutUs/whyUs';
import BeyondHorizon from './about/aboutUs/beyondHorizon';
import LeadershipTeam from './about/aboutUs/leadershipTeam';
import HeadingBar from './headingBar';

const AboutUs = () => {
  return (
    <div>
      <HeadingBar heading="About Us"/>
      <WhyUs/>
      <BeyondHorizon/>
      <LeadershipTeam/>
    </div>
  );
};

export default AboutUs;