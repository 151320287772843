import {React, useEffect} from 'react';
import Swiper from 'swiper/bundle';

const Partners = () => {

    var swiper;
    useEffect( () => {
        swiper = new Swiper(".swiper-university-partners", {
            slidesPerView: 1,
            spaceBetween: 20,
            loop: true,
            autoplay: {
              delay: 5000,
              disableOnInteraction: false,
            },
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            breakpoints: {
              575: {
                slidesPerView: 1,
                spaceBetween: 24,
                resistanceRatio: 0.85,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 28,
                resistanceRatio: 0.85,
              },
              980: {
                slidesPerView: 1,
                spaceBetween: 28,
                resistanceRatio: 0.85,
              },
              1280: {
                slidesPerView: 1,
                spaceBetween: 0,
                resistanceRatio: 0,
              },
            },
          });
    }, []);

  return (
    <section className="university-partners">
        <div className="container">
            <div className="title-sec">
                <h2>A World of Academic Allies: Our Extensive University Partnerships</h2>
                <a href="university-list">view more <i className="fa-solid fa-right-long"></i></a>
            </div>
            <div className="swiper swiper-university-partners">
                <div className="swiper-wrapper">
                    
                    <div className="swiper-slide">
                        <div className="university-logos">
                            <img src="images/trash/5. Middlesex Unicersity.svg" alt=""/>
                            <img src="images/trash/3. South Bank University.svg" alt=""/>
                            <img src="images/trash/4. University of Hertfordshire.svg" alt=""/>
                        </div>
                    </div>

                    <div className="swiper-slide">
                        <div className="university-logos">
                            <img src="images/trash/5. Middlesex Unicersity.svg" alt=""/>
                            <img src="images/trash/Raffles.png" alt=""/>
                            <img src="images/trash/UWE Bristol.svg" alt=""/>
                        </div>
                    </div>
                    
                </div>
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
                <div className="swiper-pagination"></div>
            </div>
        </div>
    </section>
  );
};

export default Partners;