import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Benefit = (props) => {
    return (
        <section className="client-say py-0 trending-sec">
            <div className="container">
                <div className="title-sec">
                    <h2>Benefits of Studying in {props.countryName}</h2>
                </div>
                <div className="benefits-main-sec">
                    <p>{props.heading}</p>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="benefits-define text-center">
                                <div className="card-first">
                                    <div className="client-details">
                                        <h4>{props.ben1Head}</h4>
                                        <p>{props.ben1Desc1}</p>
                                        <p>{props.ben1Desc2}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="benefits-define text-center">
                                <div className="card-first">
                                    <div className="client-details">
                                        <h4>{props.ben2Head}</h4>
                                        <p>{props.ben2Desc1}</p>
                                        <p>{props.ben2Desc2}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="benefits-define text-center">
                                <div className="card-first">
                                    <div className="client-details">
                                        <h4>{props.ben3Head}</h4>
                                        <p>{props.ben3Desc1}</p>
                                        <p>{props.ben3Desc2}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="benefits-define text-center">
                                <div className="card-first">
                                    <div className="client-details">
                                        <h4>{props.ben4Head}</h4>
                                        <p>{props.ben4Desc1}</p>
                                        <p>{props.ben4Desc2}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="benefits-define text-center">
                                <div className="card-first">
                                    <div className="client-details">
                                    <   h4>{props.ben5Head}</h4>
                                        <p>{props.ben5Desc1}</p>
                                        <p>{props.ben5Desc2}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="benefits-define text-center">
                                <div className="card-first">
                                    <div className="client-details">
                                        <h4>{props.ben6Head}</h4>
                                        <p>{props.ben6Desc1}</p>
                                        <p>{props.ben6Desc2}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Benefit;