import Footer from './components/footer';
import Header from './components/header';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import HomePage from './components/homepage';
import 'bootstrap/dist/css/bootstrap.min.css';
import './static/assets/front/css/style.css';
import './static/assets/front/css/swiper-bundle.min.css';
import './static/assets/front/js/bootstrap.bundle.min.js';
// import '../static/assets/front/js/custom.js';
import './static/assets/front/js/jquery.min3.3.1.js';
import './static/assets/front/js/swiper-bundle.min.js';
import AboutUs from './components/aboutUs';
import JoinTeam from './components/joinTeam.js';
import Services from './components/services.js';
import StudyDestination from './components/studyAustralia.js';
import Testimonials from './components/testimonials.js';
import ContactUs from './components/contactus.js';
import StudyAustralia from './components/studyAustralia.js';
import StudyNewZeland from './components/studyNewZeland.js';
import StudyUK from './components/studyUK.js';
import StudyUSA from './components/studyUSA.js';
import StudyCanada from './components/studyCanada.js';
// import './static/assets/front/js/custom.js';

function App() {
  return (
    <div className="App">
      <Header />
      <BrowserRouter basename='/'>
      <Routes>
        <Route path="/" element={<HomePage />}/>
        <Route path="aboutus" element={<AboutUs />} />
        <Route path="jointeam" element={<JoinTeam />} />
        <Route path="services" element={<Services />} />
        <Route path="studyaustralia" element={<StudyAustralia />} />
        <Route path="studynewzealand" element={<StudyNewZeland />} />
        <Route path="studyuk" element={<StudyUK />} />
        <Route path="studyusa" element={<StudyUSA />} />
        <Route path="studycanada" element={<StudyCanada />} />
        <Route path="testimonial" element={<Testimonials />} />
        <Route path="contactus" element={<ContactUs />} />
        <Route path="*" element={<HomePage />} />
      </Routes>
    </BrowserRouter>
      
      <Footer />
    </div>
  );
}

export default App;
