import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const CareerOpp = (props) => {
    return (
        <section className="careers-and-work">
            <div className="container">
                <div className="title-sec">
                    <h2>{props.heading}</h2>
                </div>
                <div className="opportunity-small-card">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="opportunity-card">
                                <div className="opportunity-icon">
                                    <img src={props.img1} alt="" />
                                </div>
                                <div className="opportunity-text">
                                    <p>{props.para1}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="opportunity-card">
                                <div className="opportunity-icon">
                                    <img src={props.img2} alt="" />
                                </div>
                                <div className="opportunity-text">
                                    <p>{props.para2}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="opportunity-card">
                                <div className="opportunity-icon">
                                    <img src={props.img3} alt="" />
                                </div>
                                <div className="opportunity-text">
                                    <p>{props.para3}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="opportunity-card">
                                <div className="opportunity-icon">
                                    <img src={props.img4} alt="" />
                                </div>
                                <div className="opportunity-text">
                                    <p>{props.para4}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="opportunity-card">
                                <div className="opportunity-icon">
                                    <img src={props.img5} alt="" />
                                </div>
                                <div className="opportunity-text">
                                    <p>{props.para5}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="opportunity-card">
                                <div className="opportunity-icon">
                                    <img src={props.img6} alt="" />
                                </div>
                                <div className="opportunity-text">
                                    <p>{props.para6}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4">
                            <div className="opportunity-card">
                                <div className="opportunity-icon">
                                    <img src={props.img7} alt="" />
                                </div>
                                <div className="opportunity-text">
                                    <p>{props.para7}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mobile-view-margin">
                            <div className="opportunity-card">
                                <div className="opportunity-icon">
                                    <img src={props.img8} alt="" />
                                </div>
                                <div className="opportunity-text">
                                    <p>{props.para8}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CareerOpp;